import {LipoDetailModel} from "../../shared/models/lipo-detail.model";
import {LipoTab} from "../../shared/components/models/lipoTab";
import {LipoFormModel} from "../../shared/models/lipo-form.model";
import {PartnerDetailModel} from "../components/models/partner-detail.model";
import {PartnerServiceModel} from "../services/models/partner-service.model";
import {CustomerDetailMapper} from "../../customer/mappers/customer-detail.mapper";

export class PartnerDetailMapper {
  public static toLipoDetailModel(partner: PartnerDetailModel, formModel?: LipoFormModel, tabs?: LipoTab[]): LipoDetailModel {
    return new LipoDetailModel(
      partner.name.length > 0 ? partner.name : 'partner',
      `#${partner.id}`,
      partner.logoUrl,
      formModel,
      tabs
    )
  }

  public static toPartnerServiceModel(partner: PartnerDetailModel): PartnerServiceModel {
    return {
      id: partner.id,
      name: partner.name,
      mail: partner.mail,
      phone: partner.phone,
      logo: partner.logoUrl ?? null,
      addresses: partner.addresses,
      customers: partner.customers.map(customer => CustomerDetailMapper.toCustomerServiceModel(customer))
    };
  }
}
