import {Component, inject, Input} from '@angular/core';
import {MatChipInputEvent, MatChipsModule} from "@angular/material/chips";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatIconModule} from "@angular/material/icon";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {LipoCardComponent} from "../lipo-card/lipo-card.component";
import {LipoOverviewModel} from "../models/lipo-overview.model";
import {TranslateModule} from "@ngx-translate/core";
import {I18nPluralPipe, TitleCasePipe, UpperCasePipe} from "@angular/common";
import {MatGridListModule} from "@angular/material/grid-list";

@Component({
  selector: 'du-lipo-overview',
  standalone: true,
  imports: [
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    LipoCardComponent,
    TranslateModule,
    I18nPluralPipe,
    UpperCasePipe,
    TitleCasePipe,
    MatGridListModule
  ],
  templateUrl: './lipo-overview.component.html',
  styleUrl: './lipo-overview.component.scss'
})
export class LipoOverviewComponent {
  @Input() overviewModel!: LipoOverviewModel;

  searchInput: string = '';
  keywords = ['filter1', 'filter2'];
  announcer = inject(LiveAnnouncer);

  removeFilter(keyword: string) {
    const index = this.keywords.indexOf(keyword);
    if (index >= 0) {
      this.keywords.splice(index, 1);

      this.announcer.announce(`removed ${keyword}`);
    }
  }

  addFilter(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our keyword
    if (value) {
      this.keywords.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
  }

}
