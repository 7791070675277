export class LipoButton {
  constructor(
    public text: string,
    public onClick: () => Promise<any>,
    public className?: string,
  ) {
  }

  static build(...buttons: { text: string, onClick: () => Promise<any>, className?: string }[]): LipoButton[] {
    return buttons.map(item => new LipoButton(item.text, item.onClick, item.className));
  }
}
