import {LipoFormControlTypeEnum} from "../../enums/lipo-form-control-type.enum";
import {FormControl} from "@angular/forms";

export class LipoFormControlModel {
  value: FormControl;
  key: string;
  label: string;
  order: number;
  controlType: LipoFormControlTypeEnum;
  options: { key: string, value: any }[];
  style: { stretch: boolean };

  constructor(options: {
    value?: FormControl;
    key?: string;
    label?: string;
    order?: number;
    options?: { key: string, value: any }[];
    style?: { stretch: boolean};
  } = {}) {
    this.value = options.value ?? new FormControl();
    this.key = options.key ?? '';
    this.label = options.label ?? '';
    this.order = options.order ?? 1;
    this.controlType = LipoFormControlTypeEnum.TEXT;
    this.options = options.options ?? [];
    this.style = options.style ?? {stretch: false};
  }
}

export class LipoFormTextbox extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.TEXT;
}

export class LipoFormPassword extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.PASSWORD;
}

export class LipoFormTextArea extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.TEXTAREA;
}

export class LipoFormNumeric extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.NUMERIC;
}


export class LipoFormPhone extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.PHONE;
}

export class LipoFormMail extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.MAIL;
}

export class LipoFormDropdown extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.DROPDOWN;
}

export class LipoFormCheckbox extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.CHECKBOX;
}

export class LipoFormRadio extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.RADIO;
}

export class LipoFormDate extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.DATE;
}
