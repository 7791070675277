<mat-card class="card" matRipple (click)="navigateToRoute()">
  <mat-card-header class="card-header">
    <div class="card-header-img-container">
      <img class="card-header-img"
           [ngSrc]="content.logoUrl ?? defaultLogo"
           alt="{{content.title}} logo"
           fill priority>
    </div>
  </mat-card-header>

  <mat-divider/>

  <mat-card-content class="card-content">
    <p class="mat-h3 card-content-subtitle">
      {{ content.title | translate }}
    </p>
    <p class="mat-body-2 card-content-id">
      {{ content.subtitle | translate }}
    </p>
  </mat-card-content>
  <mat-card-actions>
    <div class="container">
      @if (content.customersAmount !== null) {
        <div class="item">
          <div>
            <p class="card-action-amount card-action-amount-border">{{ content.customersAmount }}</p>
          </div>
          <div>
            <a class="card-action-type" [routerLink]="'/' + LipoRouteEnum.CUSTOMER">{{ 'customers' | translate }}</a>
          </div>
          <div>
            <mat-icon aria-hidden="false" fontIcon="chevron_left"></mat-icon>
          </div>
        </div>
      }

      @if (content.systemsAmount !== null) {
        <div class="item">
          <div>
            <p class="card-action-amount card-action-amount-border">{{ content.systemsAmount }}</p>
          </div>
          <div>
            <a class="card-action-type" [routerLink]="'/' + LipoRouteEnum.SYSTEM">{{ 'systems' | translate }}</a>
          </div>
          <div>
            <mat-icon aria-hidden="false" fontIcon="chevron_left"></mat-icon>
          </div>
        </div>
      }

      @if (content.licencesAmount !== null) {
        <div class="item">
          <div>
            <p class="card-action-amount card-action-amount-border">{{ content.licencesAmount }}</p>
          </div>
          <div>
            <a class="card-action-type" [routerLink]="'/' + LipoRouteEnum.LICENCE">{{ 'licenses' | translate }}</a>
          </div>
          <div>
            <mat-icon aria-hidden="false" fontIcon="chevron_left"></mat-icon>
          </div>
        </div>
      }

      @if (content.productsAmount !== null) {
        <div class="item">
          <div>
            <p class="card-action-amount card-action-amount-border">{{ content.productsAmount }}</p>
          </div>
          <div>
            <a class="card-action-type" [routerLink]="'/' + LipoRouteEnum.PRODUCT">{{ 'products' | translate }}</a>
          </div>
          <div>
            <mat-icon aria-hidden="false" fontIcon="chevron_left"></mat-icon>
          </div>
        </div>
      }

      @if (content.tenantsAmount !== null) {
        <div class="item">
          <div>
            <p class="card-action-amount card-action-amount-border">{{ content.tenantsAmount }}</p>
          </div>
          <div>
            <a class="card-action-type">{{ 'tenants' | translate }}</a>
          </div>
          <div>
            <mat-icon aria-hidden="false" fontIcon="chevron_left"></mat-icon>
          </div>
        </div>
      }
    </div>
  </mat-card-actions>
</mat-card>
