import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ConfigurationService} from "../../shared/services/configuration.service";
import {ProductServiceModel} from "./models/product-service.model";
import {PartnerServiceModel} from "../../partner/services/models/partner-service.model";

@Injectable({
  providedIn: 'root'
})
export class ProductDataService {

  private readonly endpoint: string = "product";
  private readonly api: string;

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
  ) {
    this.api = this.config.configuration.api.backendUrl;
  }

  createProduct(product: ProductServiceModel): Observable<ProductServiceModel> {
    return this.http.post<ProductServiceModel>(`${this.api}/${this.endpoint}`, product);
  }

  getProducts(): Observable<ProductServiceModel[]> {
    return this.http.get<ProductServiceModel[]>(`${this.api}/${this.endpoint}`);
  }

  getProduct(id: number): Observable<ProductServiceModel> {
    return this.http.get<ProductServiceModel>(`${this.api}/${this.endpoint}/${id}`);
  }

  updateProduct(product: ProductServiceModel): Observable<ProductServiceModel> {
    return this.http.put<ProductServiceModel>(`${this.api}/${this.endpoint}/${product.id}`, product);
  }

  deleteProduct(id: number): Observable<void> {
    return this.http.delete<void>(`${this.api}/${this.endpoint}/${id}`);
  }
}
