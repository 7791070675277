import {Component, OnInit} from '@angular/core';
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {TenantDetailModel} from "../models/tenant-detail.model";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {TenantDetailMapper} from "../../mappers/tenant-detail.mapper";
import {Observable, of} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {
  LipoFormCheckbox,
  LipoFormControlModel,
  LipoFormNumeric,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";
import {TenantServiceMapper} from "../../mappers/tenant-service.mapper";
import {TenantDataService} from "../../services/tenant-data.service";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {SystemDataService} from "../../../system/services/system-data.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'du-tenant-detail',
  standalone: true,
  imports: [
    LipoDetailComponent
  ],
  templateUrl: './tenant-detail.component.html',
  styleUrl: './tenant-detail.component.scss'
})
export class TenantDetailComponent implements OnInit {
  detailModel?: LipoDetailModel;
  backupTenant?: TenantDetailModel;
  systemId: number | null = null;
  tenantId: number | null = null;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _snackBar: SnackbarService,
    private _tenantService: TenantDataService,
    private _systemService: SystemDataService,
    private _translationService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        this.handleRouteData(data);
      }
    });
  }

  private handleRouteData(data: Data): void {
    const routesDataModel = data as LipoRoutesDataModel;
    this.handleParams();
    this.handleTenantData(routesDataModel);
  }

  private handleParams(): void {
    this._activatedRoute.paramMap.subscribe(params => {
      this.systemId = this.idToNumber(params.get('systemId'));
      this.tenantId = this.idToNumber(params.get('tenantId'));
    });
  }

  private idToNumber(idString: string | null): number | null {
    if (idString === null) {
      return null;
    }
    const num = +idString;
    return isNaN(num) ? null : num;
  }

  private handleTenantData(routesDataModel: LipoRoutesDataModel): void {
    if (routesDataModel.isCreateItem) {
      this.createEmptyTenant();
    } else {
      if (this.systemId !== null) {
        this._systemService.getSystem(this.systemId).subscribe({
          next: value => {
            let tenantServiceModel = value.tenants.find(tenant => tenant.id === this.tenantId)
            if (tenantServiceModel !== undefined) {
              let tenant = TenantServiceMapper.toTenantDetailModel(tenantServiceModel);
              this.backupTenant = tenant;
              this.setTenant(tenant);
            } else {
              this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId]).then(() => this._snackBar.Warning(this._translationService.instant('snackbar.notfound')))
            }
          }
        });
      }
    }
  }
  setTenant(tenant: TenantDetailModel): void {
    this.createDetail(tenant);
  }


  createEmptyTenant(): void {
    let emptyTenantModel = new TenantDetailModel();
    this.createDetail(emptyTenantModel);
  }

  createDetail(tenantModel: TenantDetailModel): void {
    this.getFormFields(tenantModel).subscribe({
      next: baseForms => {
        let lipoFormModel = new LipoFormModel(baseForms)
        this.detailModel = TenantDetailMapper.toLipoDetailModel(tenantModel, lipoFormModel)
      }
    });
  }

  getFormFields(model: TenantDetailModel): Observable<LipoFormGroupModel[]> {
    const checkBoxes: LipoFormControlModel[] = [
      new LipoFormCheckbox({
        value: new FormControl(model.active),
        key: 'active',
        label: 'active',
      }),
      new LipoFormCheckbox({
        value: new FormControl(model.checkInbound),
        key: 'checkInbound',
        label: 'check_inbound',
      }),
      new LipoFormCheckbox({
        value: new FormControl(model.testSystem),
        key: 'testSystem',
        label: 'testSystem',
      }),
      new LipoFormCheckbox({
        value: new FormControl(model.suspended),
        key: 'suspended',
        label: 'suspended',
      }),
    ]
    const technicalFields: LipoFormControlModel[] = [
      new LipoFormNumeric({
        value: new FormControl(model.port),
        key: 'port',
        label: 'port',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.serviceName),
        key: 'serviceName',
        label: 'serviceName',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.duifLicense),
        key: 'duifLicense',
        label: 'duifLicense',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.installNo),
        key: 'installNo',
        label: 'installNo',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.endpointUrl),
        key: 'endpointUrl',
        label: 'endpointUrl',
      }),
    ]

    const gitlabFields: LipoFormControlModel[] = [
      new LipoFormTextbox({
        value: new FormControl(model.gitlabJobName),
        key: 'gitlabJobName',
        label: 'gitlab_job_name',
      }),
      new LipoFormNumeric({
        value: new FormControl(model.gitlabProjectId),
        key: 'gitlabProjectId',
        label: 'gitlab_project_id',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.gitlabRefName),
        key: 'gitlabRefName',
        label: 'gitlab_ref_name',
      }),
    ];
    const artifactFields: LipoFormControlModel[] = [
      new LipoFormTextbox({
        value: new FormControl(model.artifactDate),
        key: 'artifactDate',
        label: 'artifactDate',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.directory),
        key: 'directory',
        label: 'directory',
      }),
    ]
    const sapFields: LipoFormControlModel[] = [
      new LipoFormTextbox({
        value: new FormControl(model.sapCompanyDB, Validators.required),
        key: 'sapCompanyDB',
        label: 'sapCompanyDB',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.sapUser),
        key: 'sapUser',
        label: 'sapUser',
      }),
    ];
    return of([
      new LipoFormGroupModel({key: 'sap', label: 'sap', order: 1, controls: sapFields}),
      new LipoFormGroupModel({key: 'artifact', label: 'artifact', order: 2, controls: artifactFields}),
      new LipoFormGroupModel({key: 'gitlab', label: 'gitlab', order: 3, controls: gitlabFields}),
      new LipoFormGroupModel({key: 'technical', label: 'technical', order: 4, controls: technicalFields}),
      new LipoFormGroupModel({key: 'checkBoxes', order: 4, controls: checkBoxes}),
    ]);
  }

  onDeleteClicked(): void {
    if (!this.tenantId || !this.systemId) return;

    this._tenantService.deleteTenant(this.tenantId, this.systemId).subscribe({
      next: (systemModel) => {
        if (systemModel) {
          this._router.navigate([
            LipoRouteEnum.SYSTEM,
            systemModel.id,
          ]).then(() => this._snackBar.Deleted());
        }
      }
    });
  }

  onSaveClick(detailModel: LipoDetailModel): void {
    if (!detailModel.form?.formGroup || !this.systemId) return;

    let serviceTenantModel = LipoFormMapper.toTenantSaveModel(detailModel.form?.formGroup, this.backupTenant)
    let id = this.tenantId;

    if (id && id > 0) {
      this._tenantService.updateTenant(serviceTenantModel, this.systemId).subscribe({
        next: tenant => {
          this.setTenant(TenantServiceMapper.toTenantDetailModel(tenant))
          this._snackBar.Saved();
        }
      });
    } else {
      this._tenantService.createTenant(serviceTenantModel, this.systemId).subscribe({
        next: tenant => {
          this._router.navigate([
            LipoRouteEnum.SYSTEM,
            this.systemId,
            LipoRouteEnum.TENANT,
            tenant.id
          ], {state: {tenant}}).then(() => this._snackBar.Saved());
        }
      });
    }
  }

  protected readonly LipoRouteEnum = LipoRouteEnum;
}
