@if (formModel) {
  <form class="form-container" [formGroup]="formModel.formGroup">
    <div class="form-body">
      <div class="form-row" [style.gap]="formModel.style.gap + 'px'">
        @for (baseForm of formModel.baseForms; track $index) {
          @if (isFormGroup(baseForm)) {
            <div formGroupName="{{ baseForm.key }}" class="nested-form-group">
              <h4 style="height: 20px">{{ baseForm.label | translate }}</h4>
              <div class="form-row" [style.gap]="formGroup(baseForm).style.gap + 'px'">
                @for (subForm of getControlsFromFormGroup(baseForm); track $index) {
                  <div [ngClass]="subForm.style.stretch ? 'form-item-stretch' : 'form-item'">
                    @if (subForm.controlType !== LipoFormControlTypeEnum.CHECKBOX
                    && subForm.controlType !== LipoFormControlTypeEnum.RADIO
                    && subForm.controlType !== LipoFormControlTypeEnum.DATE) {
                      <mat-form-field class="form-field-transparent-background" appearance="fill">
                        <mat-label>{{ subForm.label | translate }}</mat-label>
                        @switch (subForm.controlType) {
                          @case (LipoFormControlTypeEnum.TEXT) {
                            <input matInput [formControlName]="subForm.key">
                          }
                          @case (LipoFormControlTypeEnum.TEXTAREA) {
                            <textarea matInput [formControlName]="subForm.key"></textarea>
                          }
                          @case (LipoFormControlTypeEnum.NUMERIC) {
                            <input matInput type="number" [formControlName]="subForm.key">
                          }
                          @case (LipoFormControlTypeEnum.MAIL) {
                            <input matInput type="email" [formControlName]="subForm.key">
                          }
                          @case (LipoFormControlTypeEnum.PHONE) {
                            <input matInput type="tel" [formControlName]="subForm.key">
                          }
                          @case (LipoFormControlTypeEnum.DROPDOWN) {
                            <mat-select [formControlName]="subForm.key">
                              @for (option of subForm.options; track option) {
                                <mat-option [value]="option.value">{{ option.key | translate }}</mat-option>
                              }
                            </mat-select>
                          }
                        }
                      </mat-form-field>
                    }
                    @switch (subForm.controlType) {
                      @case (LipoFormControlTypeEnum.CHECKBOX) {
                        <mat-checkbox [formControlName]="subForm.key">{{ subForm.label | translate }}</mat-checkbox>
                      }
                      @case (LipoFormControlTypeEnum.RADIO) {
                        <mat-radio-group [formControlName]="subForm.key">
                          @for (option of subForm.options; track option) {
                            <mat-radio-button [value]="option.value">{{ option.key }}</mat-radio-button>
                          }
                        </mat-radio-group>
                      }
                      @case (LipoFormControlTypeEnum.PASSWORD) {
                        <input matInput type="password" [formControlName]="subForm.key">
                      }
                      @case (LipoFormControlTypeEnum.DATE) {
                        <mat-form-field class="form-field-transparent-background" appearance="fill">
                          <mat-label>{{ baseForm.label | translate }}</mat-label>
                          <input matInput [matDatepicker]="picker1" [formControlName]="baseForm.key">
                          <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                          <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                      }
                    }
                  </div>
                }
              </div>
            </div>
          } @else {
            <div [ngClass]="formControl(baseForm).style.stretch ? 'form-item-stretch' : 'form-item'">
              @if (formControl(baseForm).controlType !== LipoFormControlTypeEnum.CHECKBOX
              && formControl(baseForm).controlType !== LipoFormControlTypeEnum.RADIO
              && formControl(baseForm).controlType !== LipoFormControlTypeEnum.DATE) {
                <mat-form-field class="form-field-transparent-background" appearance="fill">
                  <mat-label>{{ baseForm.label | translate }}</mat-label>
                  @switch (formControl(baseForm).controlType) {
                    @case (LipoFormControlTypeEnum.TEXT) {
                      <input matInput [formControlName]="baseForm.key">
                    }
                    @case (LipoFormControlTypeEnum.TEXTAREA) {
                      <textarea matInput [formControlName]="baseForm.key"></textarea>
                    }
                    @case (LipoFormControlTypeEnum.NUMERIC) {
                      <input matInput type="number" [formControlName]="baseForm.key">
                    }
                    @case (LipoFormControlTypeEnum.MAIL) {
                      <input matInput type="email" [formControlName]="baseForm.key">
                    }
                    @case (LipoFormControlTypeEnum.PHONE) {
                      <input matInput type="tel" [formControlName]="baseForm.key">
                    }
                    @case (LipoFormControlTypeEnum.DROPDOWN) {
                      <mat-select [formControlName]="baseForm.key">
                        @for (option of formControl(baseForm).options; track option) {
                          <mat-option [value]="option.value">{{ option.key | translate }}</mat-option>
                        }
                      </mat-select>
                    }
                  }
                </mat-form-field>
              }
              @switch (formControl(baseForm).controlType) {
                @case (LipoFormControlTypeEnum.CHECKBOX) {
                  <mat-checkbox [formControlName]="baseForm.key">{{ baseForm.label | translate }}</mat-checkbox>
                }
                @case (LipoFormControlTypeEnum.RADIO) {
                  <mat-radio-group [formControlName]="baseForm.key">
                    @for (option of formControl(baseForm).options; track option) {
                      <mat-radio-button [value]="option.value">{{ option.key }}</mat-radio-button>
                    }
                  </mat-radio-group>
                }
                @case (LipoFormControlTypeEnum.PASSWORD) {
                  <input matInput type="password" [formControlName]="baseForm.key">
                }
                @case (LipoFormControlTypeEnum.DATE) {
                  <mat-form-field class="form-field-transparent-background" appearance="fill">
                    <mat-label>{{ baseForm.label | translate }}</mat-label>
                    <input matInput [matDatepicker]="picker2" [formControlName]="baseForm.key">
                    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                }
              }
            </div>
          }
        }
      </div>
    </div>
  </form>
}
