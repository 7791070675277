import {LipoTab} from "../components/models/lipoTab";
import {LipoFormModel} from "./lipo-form.model";
import {LipoTableModel} from "../components/models/lipo-table.model";
import {Type} from "@angular/core";
import {isLipoTableModel, isTypeAny} from "../../utils/type.utils";

export class LipoDetailModel {
  constructor(
    public title: string,
    public subtitle: string,
    public logoUrl: string | null,
    public form?: LipoFormModel,
    public tabs?: LipoTab[],
    public id?: number | null,
  ) {
  }

  /**
   * Get all components of type `Type<any>`or `LipoTableModel` in all tabs
   *
   * @returns Component for provided LipoDetailModel or null if not found
   * @param componentUuid
   */
  findComponent<T extends Type<any> | LipoTableModel<any>>(
    componentUuid: string
  ): T | null {
    const tabs = this.tabs || [];

    for (const tab of tabs) {
      const components = tab.components || [];

      for (const component of components) {
        const componentInstance = component.component;

        if (component.uuid === componentUuid) {
          if (isLipoTableModel(componentInstance) || isTypeAny(componentInstance)) {
            return componentInstance as T;
          }
        }
      }
    }

    return null;
  }
}
