import {PartnerOverviewModel} from "../components/models/partner-overview.model";
import {LipoCardModel} from "../../shared/models/lipo-card.model";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";

export class PartnerCardMapper {
  public static toLipoCardModel(partnerOverviewModel: PartnerOverviewModel): LipoCardModel {
    return new LipoCardModel(
      partnerOverviewModel.id,
      LipoRouteEnum.PARTNER,
      partnerOverviewModel.name,
      '',
      partnerOverviewModel.logoUrl,
      partnerOverviewModel.licenses.length,
      partnerOverviewModel.customers.length,
      partnerOverviewModel.systems.length,
      null
      )
  }
}
