import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {LipoObjectTypeEnum} from "../../../shared/enums/lipo-object-type.enum";

export class TenantDetailModel implements LipoModelInterface {

  constructor(
    public active: boolean = false,
    public isActiveText: 'active' | 'idle' | null = "idle",
    public artifactDate: string | null = null,
    public checkInbound: boolean = false,
    public directory: string | null = null,
    public gitlabJobName:string | null = null,
    public gitlabProjectId: number | null = null,
    public gitlabRefName: string | null = null,
    public port: number | null = null,
    public sapCompanyDB: string = '',
    public sapPassword: string | null = null,
    public sapUser: string | null = null,
    public serviceName: string |null = null,
    public testSystem: boolean = false,
    public suspended: boolean = false,
    public duifLicense: string | null = null,
    public installNo: string | null = null,
    public endpointUrl: string | null = null,
    public id: number | null = null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }

  getType(): LipoObjectTypeEnum {
    return LipoObjectTypeEnum.TENANT;
  }
}
