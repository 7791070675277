import {LipoRouteEnum} from "../enums/lipo-route.enum";

export class LipoCardModel {
  constructor(
    public id: number | null,
    public route: LipoRouteEnum,
    public title: string,
    public subtitle: string,
    public logoUrl: string | null = null,
    public licencesAmount: number | null = null,
    public customersAmount: number | null = null,
    public systemsAmount: number | null = null,
    public productsAmount: number | null = null,
    public tenantsAmount: number | null = null,
  ) {
  }
}
