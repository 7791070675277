import {Component, Input} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {LipoFormControlTypeEnum} from "../../enums/lipo-form-control-type.enum";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {LipoFormModel} from "../../models/lipo-form.model";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {TranslateModule} from "@ngx-translate/core";
import {NgClass} from "@angular/common";
import {LipoFormGroupModel} from "../models/lipo-form-group.model";
import {LipoFormControlModel, LipoFormNumeric} from "../models/lipo-form-control.model";
import {MatDatepickerModule} from "@angular/material/datepicker";

@Component({
  selector: 'du-lipo-form',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    TranslateModule,
    NgClass,
    MatDatepickerModule,
  ],
  templateUrl: './lipo-form.component.html',
  styleUrl: './lipo-form.component.scss'
})
export class LipoFormComponent {
  @Input() formModel!: LipoFormModel;

  constructor() {
  }

  protected readonly LipoFormControlTypeEnum = LipoFormControlTypeEnum;

  isFormGroup(baseForm: any): boolean{
    return baseForm instanceof LipoFormGroupModel;
  }

  formControl(baseForm: any){
    return baseForm as LipoFormControlModel;
  }

  formGroup(baseForm: any){
    return baseForm as LipoFormGroupModel;
  }

  getControlsFromFormGroup(baseForm: LipoFormControlModel | LipoFormGroupModel): LipoFormControlModel[] {
    if (baseForm instanceof LipoFormControlModel) return [];
    return baseForm.controls
  }

  protected readonly LipoFormNumeric = LipoFormNumeric;
}
