import {SystemOverviewModel} from "../../../system/services/models/system-overview.model";

export class CustomerOverviewModel {
  constructor(
    public id: number | null,
    public name: string,
    public phone: string,
    public logoUrl: string | null,
    public systems: SystemOverviewModel[] = []
  ) {
  }
}
