import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {LipoObjectTypeEnum} from "../../../shared/enums/lipo-object-type.enum";
import {Address} from "../../../shared/models/lipo-address.model";
import {SystemDetailModel} from "../../../system/components/models/system-detail.model";
import {PartnerDetailModel} from "../../../partner/components/models/partner-detail.model";

export class CustomerDetailModel implements LipoModelInterface {
  constructor(
    public id: number | null = null,
    public name: string = '',
    public mail: string = '',
    public phone: string = '',
    public partner: PartnerDetailModel | number | null = null,
    public addresses: Address[] = [],
    public systems: SystemDetailModel[] = [],
    public logoUrl: string | null = null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }

  getType(): LipoObjectTypeEnum {
    return LipoObjectTypeEnum.CUSTOMER;
  }
}
