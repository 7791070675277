import {CustomerOverviewModel} from "../../../customer/components/models/customer-overview.model";
import {SystemOverviewModel} from "../../../system/services/models/system-overview.model";
import {LicenceOverviewModel} from "../../../licence/components/models/licence-overview.model";

export class ProductOverviewModel {
  constructor(
    public id: number | null,
    public name: string,
    public description: string,
    public image: string,
  ) { }
}
