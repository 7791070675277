import {Routes} from '@angular/router';
import {AuthGuardService} from "./keycloak/services/auth-guard.service";
import {PartnerOverviewComponent} from "./partner/components/partner-overview/partner-overview.component";
import {PartnerDetailComponent} from "./partner/components/partner-detail/partner-detail.component";
import {LipoRouteEnum} from "./shared/enums/lipo-route.enum";
import {LipoRoutesDataModel} from "./shared/models/lipo-routes-data.model";
import {KeycloakRoleEnum} from "./shared/enums/keycloak-role.enum";
import {SystemDetailComponent} from "./system/components/system-detail/system-detail.component";
import {CustomerOverviewComponent} from "./customer/components/customer-overview/customer-overview.component";
import {CustomerDetailComponent} from "./customer/components/customer-detail/customer-detail.component";
import {SystemOverviewComponent} from "./system/components/system-overview/system-overview.component";
import {TenantDetailComponent} from "./tenant/components/tenant-detail/tenant-detail.component";
import {ProductOverviewComponent} from "./product/components/product-overview/product-overview.component";
import {ProductDetailComponent} from "./product/components/prodcut-detail/product-detail.component";
import {
  ProductPriceDetailComponent
} from "./productPrice/components/product-price-detail/product-price-detail.component";

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuardService], // this is the parent route with AuthGuard
    children: [
      {
        path: LipoRouteEnum.PARTNER,
        children: [
          {
            path: '',
            component: PartnerOverviewComponent,
            data: {roles: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER]} as LipoRoutesDataModel,
          },
          {
            path: 'new',
            component: PartnerDetailComponent,
            data: {
              isCreateItem: true,
              roles: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER]
            } as LipoRoutesDataModel
          },
          {
            path: ':id',
            component: PartnerDetailComponent,
            data: {
              isCreateItem: false,
              roles: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER]
            } as LipoRoutesDataModel
          },
        ]
      },
      {
        path: LipoRouteEnum.CUSTOMER,
        children: [
          {
            path: '',
            component: CustomerOverviewComponent,
            data: {roles: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER, KeycloakRoleEnum.CUSTOMER]} as LipoRoutesDataModel,
          },
          {
            path: 'new',
            component: CustomerDetailComponent,
            data: {
              isCreateItem: true,
              roles: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER, KeycloakRoleEnum.CUSTOMER]
            } as LipoRoutesDataModel
          },
          {
            path: ':id',
            component: CustomerDetailComponent,
            data: {
              isCreateItem: false,
              roles: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER, KeycloakRoleEnum.CUSTOMER]
            } as LipoRoutesDataModel
          },
        ]
      },
      {
        path: LipoRouteEnum.SYSTEM,
        children: [
          {
            path: '',
            component: SystemOverviewComponent,
            data: {roles: []} as LipoRoutesDataModel,
          },
          {
            path: 'new',
            component: SystemDetailComponent,
            data: {
              isCreateItem: true,
              roles: []
            } as LipoRoutesDataModel
          },
          {
            path: ':systemId',
            children: [
              {
                path: '',
                component: SystemDetailComponent,
                data: {
                  isCreateItem: false,
                  roles: []
                } as LipoRoutesDataModel
              },
              {
                path: LipoRouteEnum.TENANT,
                children: [
                  {
                    path: 'new',
                    component: TenantDetailComponent,
                    data: {
                      isCreateItem: true,
                      roles: []
                    } as LipoRoutesDataModel
                  },
                  {
                    path: ':tenantId',
                    component: TenantDetailComponent,
                    data: {
                      isCreateItem: false,
                      roles: []
                    } as LipoRoutesDataModel
                  },
                ]
              }
            ]
          }
        ]
      },
      {
        path: LipoRouteEnum.PRODUCT,
        children: [
          {
            path: '',
            component: ProductOverviewComponent,
            data: {roles: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER]} as LipoRoutesDataModel,
          },
          {
            path: 'new',
            component: ProductDetailComponent,
            data: {
              isCreateItem: true,
              roles: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER]
            } as LipoRoutesDataModel
          },
          {
            path: ':productId',
            children: [
              {
                path: '',
                component: ProductDetailComponent,
                data: {
                  isCreateItem: false,
                  roles: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER]
                } as LipoRoutesDataModel,
              },
              {
                path: LipoRouteEnum.PRODUCT_PRICE,
                children: [
                  {
                    path: '',
                    component: ProductPriceDetailComponent,
                    data: {roles: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER]} as LipoRoutesDataModel,
                  },
                  {
                    path: 'new',
                    component: ProductPriceDetailComponent,
                    data: {
                      isCreateItem: true,
                      roles: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER]
                    } as LipoRoutesDataModel,
                  },
                  {
                    path: ':productPriceId',
                    component: ProductPriceDetailComponent,
                    data: {
                      isCreateItem: false,
                      roles: [KeycloakRoleEnum.ADMIN, KeycloakRoleEnum.PARTNER]
                    } as LipoRoutesDataModel,
                  },
                ]
              }
            ]
          },
        ]
      }
    ]
  },
];
