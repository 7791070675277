import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {CustomerServiceModel, Page} from "./models/customer-service.model";
import {HttpClient} from "@angular/common/http";
import {ICustomerDataInterface} from "./cusomter-data.interface";
import {ConfigurationService} from "../../shared/services/configuration.service";

@Injectable({
  providedIn: 'root'
})
export class CustomerDataService implements ICustomerDataInterface {

  private readonly endpoint: string;
  private readonly api:string;

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
  ) {
    this.endpoint = this.config.configuration.api.endpoints.customer;
    this.api = this.config.configuration.api.backendUrl;
  }

  public getCustomers(): Observable<Page<CustomerServiceModel>> {
    return this.http.get<Page<CustomerServiceModel>>(`${this.api}/${this.endpoint}`);
  }

  public getCustomer(id: number): Observable<CustomerServiceModel> {
    return this.http.get<CustomerServiceModel>(`${this.api}/${this.endpoint}/${id}`);
  }

  public createCustomer(customer: CustomerServiceModel): Observable<CustomerServiceModel> {
    return this.http.post<CustomerServiceModel>(`${this.api}/${this.endpoint}`, customer);
  }

  public updateCustomer(customer: CustomerServiceModel): Observable<CustomerServiceModel> {
    return this.http.put<CustomerServiceModel>(`${this.api}/${this.endpoint}/${customer.id}`, customer);
  }

  public deleteCustomer(id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.api}/${this.endpoint}/${id}`);
  }

}
