import {LipoCardModel} from "../../shared/models/lipo-card.model";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";
import {ProductOverviewModel} from "../components/models/product-overview.model";

export class ProductCardMapper {
  public static toLipoCardModel(productOverviewModel: ProductOverviewModel): LipoCardModel {
    return new LipoCardModel(
      productOverviewModel.id,
      LipoRouteEnum.PRODUCT,
      productOverviewModel.name,
      productOverviewModel.description,
      productOverviewModel.image || null
      )
  }
}
