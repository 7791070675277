import {CustomerOverviewModel} from "../components/models/customer-overview.model";
import {LipoCardModel} from "../../shared/models/lipo-card.model";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";

export class CustomerOverviewMapper {
  public static toLipoCard(customerOverviewModel: CustomerOverviewModel): LipoCardModel {
    return new LipoCardModel(
      customerOverviewModel.id,
      LipoRouteEnum.CUSTOMER,
      customerOverviewModel.name,
      customerOverviewModel.phone,
      customerOverviewModel.logoUrl,
      null,
      null,
      customerOverviewModel.systems.length,
      null
    )
  }
}
