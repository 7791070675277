<div class="dialog-body">
  @if (data.title) {
    <h2 mat-dialog-title class="dialog-title">{{ data.title }}</h2>
  }
  @if (data.content) {
    <mat-dialog-content>{{ data.content }}</mat-dialog-content>
  }

  <mat-dialog-actions [align]="'end'">
    <button mat-button
            [mat-dialog-close]="false">{{
        data.cancelButtonText ? (data.cancelButtonText | uppercase) :
          ('button.cancel' | translate | uppercase)
      }}
    </button>
    @if (data.confirmButtonText) {
      <button mat-button [mat-dialog-close]="true">{{ data.confirmButtonText | uppercase }}</button>
    }
  </mat-dialog-actions>
</div>

