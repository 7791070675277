import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {LipoObjectTypeEnum} from "../../../shared/enums/lipo-object-type.enum";
import {ProductPriceDetailModel} from "../../../productPrice/components/models/product-price-detail.model";

export class ProductDetailModel implements LipoModelInterface {
  constructor(
    public name: string = '',
    public description: string = '',
    public partnerId?: number,
    public productPrice: ProductPriceDetailModel[] = [],
    public image: string = '',
    public url?: string,
    public id: number | null = null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }

  getType(): LipoObjectTypeEnum {
    return LipoObjectTypeEnum.PRODUCT;
  }

}
