<div class="container">
  <div class="container-header">
    <div class="container-header-content">
      <div class="dense-3">
        <button mat-icon-button matTooltip="{{'back' | translate}}" class="detail-header-button-back" (click)="handleNavigateBack()">
          <mat-icon fontIcon="chevron_left"></mat-icon>
        </button>
      </div>
      @if (detailModel.logoUrl && detailModel.logoUrl.length > 0) {
        <div class="detail-header-img-container">
          <img
               [ngSrc]="detailModel.logoUrl" class="card-header-img"
               alt="{{detailModel.title}} logo"
               width="64" height="64" priority>
        </div>
      }
      <div class="detail-header-title-container">
        <h1 class="detail-title">
          {{ detailModel.title | translate }}
        </h1>
        <p class="detail-subtitle">
          {{ getSubtitle() | translate }}
        </p>
      </div>

    </div>

    <div>
      <div class="container-header-action">
        <button *ngIf="showDownloadButton" mat-icon-button (click)="onDownloadIconClicked()">
          <mat-icon fontIcon="file_download"></mat-icon>
        </button>
        <button mat-icon-button (click)="onSaveIconClicked()" [disabled]="!isFormValid()">
          <mat-icon fontIcon="save"/>
        </button>
        <button mat-icon-button (click)="onDeleteIconClicked()" [hidden]="isHidden()">
          <mat-icon fontIcon="delete"/>
        </button>
      </div>
    </div>
  </div>
  <div>
    <mat-card>
      <mat-card-header class="detail-card-header">
        <mat-card-title>{{'details' | translate}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        @if (detailModel.form) {
          <du-lipo-form [formModel]="detailModel.form"></du-lipo-form>
        }
        @if (detailModel.tabs) {
          <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
            @for (tab of detailModel.tabs; track tab) {
              <mat-tab [label]="tab.title | translate">
                @for (component of tab.components; track component) {
                  @if (isTypeAny(component.component)) {
                    <ng-container *ngComponentOutlet="component.component; inputs: component.inputs;"/>
                  } @else if (isLipoTableModel(component.component)) {
                    <du-lipo-table [lipoTable]="component.component"></du-lipo-table>
                  }
                }
              </mat-tab>
            }
          </mat-tab-group>

        }
      </mat-card-content>
    </mat-card>
  </div>
</div>


