import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from "rxjs";
import {ITenantDataInterface} from "./tenant-data.interface";
import {ConfigurationService} from "../../shared/services/configuration.service";
import {TenantServiceModel} from "./models/tenant-service.model";
import {SystemServiceModel} from "../../system/services/models/system-service.model";

@Injectable({
  providedIn: 'root'
})
export class TenantDataService implements ITenantDataInterface {

  private readonly endpoint: string;
  private readonly systemEndpoint: string;
  private readonly api: string;

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
  ) {
    this.endpoint = this.config.configuration.api.endpoints.tenant;
    this.systemEndpoint = this.config.configuration.api.endpoints.system;
    this.api = this.config.configuration.api.backendUrl;
  }

  createTenant(tenant: TenantServiceModel, systemId: number): Observable<TenantServiceModel> {
    return this.http.post<SystemServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.endpoint}`, tenant).pipe(
      map((systemServiceModel: SystemServiceModel) => this.getTenant(systemServiceModel, tenant.id))
    );
  }

  deleteTenant(tenantId: number, systemId: number): Observable<SystemServiceModel> {
    return this.http.delete<SystemServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.endpoint}/${tenantId}`);
  }

  updateTenant(tenant: TenantServiceModel, systemId: number): Observable<TenantServiceModel> {
    return this.http.put<SystemServiceModel>(`${this.api}/${this.systemEndpoint}/${systemId}/${this.endpoint}/${tenant.id}`, tenant).pipe(
      map((systemServiceModel: SystemServiceModel) => this.getTenant(systemServiceModel, tenant.id))
    );
  }

  private getTenant(systemServiceModel: SystemServiceModel, tenantId: number | null): TenantServiceModel {
    const updatedTenant = systemServiceModel.tenants.find(tenant => tenant.id === tenantId);
    return updatedTenant || {} as TenantServiceModel;
  }
}
