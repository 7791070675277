import {LipoCardModel} from "../../shared/models/lipo-card.model";
import {SystemOverviewModel} from "../services/models/system-overview.model";
import {LipoRouteEnum} from "../../shared/enums/lipo-route.enum";

export class SystemOverviewMapper {
  public static toLipoCard(systemOverviewModel: SystemOverviewModel): LipoCardModel {
    return new LipoCardModel(
      systemOverviewModel.id,
      LipoRouteEnum.SYSTEM,
      systemOverviewModel.name,
      '',
      systemOverviewModel.logo,
      null,
      null,
      null,
      null,
      systemOverviewModel.tenants.length
    );
  }
}
