import {TenantServiceModel} from "../services/models/tenant-service.model";
import {TenantDetailModel} from "../components/models/tenant-detail.model";

export class TenantServiceMapper {
  public static toTenantDetailModel(tenantServiceModel: TenantServiceModel): TenantDetailModel {
    return new TenantDetailModel(
      tenantServiceModel.active,
      tenantServiceModel.active ? "active" : "idle",
      tenantServiceModel.artifactDate,
      tenantServiceModel.checkInbound,
      tenantServiceModel.directory,
      tenantServiceModel.gitlabJobName,
      tenantServiceModel.gitlabProjectId,
      tenantServiceModel.gitlabRefName,
      tenantServiceModel.port === 0 ? null : tenantServiceModel.port,
      tenantServiceModel.sapCompanyDB,
      tenantServiceModel.sapPassword,
      tenantServiceModel.sapUser,
      tenantServiceModel.serviceName,
      tenantServiceModel.testSystem,
      tenantServiceModel.suspended,
      tenantServiceModel.duifLicense ?? '',
      tenantServiceModel.installNo ?? '',
      tenantServiceModel.endpointUrl ?? '',
      tenantServiceModel.id
    )
  }
}
