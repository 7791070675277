import {Component, OnInit} from '@angular/core';
import {CustomerDataService} from "../../services/customer-data.service";
import {LipoOverviewComponent} from "../../../shared/components/lipo-overview/lipo-overview.component";
import {LipoOverviewModel} from "../../../shared/components/models/lipo-overview.model";
import {Router} from "@angular/router";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {LipoObjectTypeEnum} from "../../../shared/enums/lipo-object-type.enum";
import {CustomerServiceMapper} from "../../mappers/customer-service.mapper";
import {CustomerOverviewMapper} from "../../mappers/customer-overview.mapper";
import {LipoButton} from "../../../shared/components/models/lipo-button";

@Component({
  selector: 'du-customer',
  standalone: true,
  imports: [
    LipoOverviewComponent
  ],
  templateUrl: './customer-overview.component.html',
  styleUrl: './customer-overview.component.scss'
})
export class CustomerOverviewComponent implements OnInit {
  customerOverviewModel?: LipoOverviewModel;


  constructor(
    private customerService: CustomerDataService,
    protected router: Router) {
  }

  ngOnInit(): void {
    this.customerService.getCustomers().subscribe({
      next: customers => {
        let customersOverview = customers.content.map(CustomerServiceMapper.toCustomerOverviewModel);
        let customerCards = customersOverview.map(CustomerOverviewMapper.toLipoCard);

        this.customerOverviewModel = {
          title: LipoObjectTypeEnum.CUSTOMER,
          buttons: LipoButton.build({
            text: "button.customer.add",
            onClick: async () => await this.router.navigate([LipoRouteEnum.CUSTOMER, 'new'])
          }),
          cards: customerCards
        }
      }
    });
  }

  protected readonly LipoRouteEnum = LipoRouteEnum;
}
