import {PartnerDetailModel} from "../../partner/components/models/partner-detail.model";
import {FormGroup} from "@angular/forms";
import {Address} from "../models/lipo-address.model";
import {CustomerDetailModel} from "../../customer/components/models/customer-detail.model";
import {SystemDetailModel, SystemSaveDetailModel} from "../../system/components/models/system-detail.model";
import {CustomerDetailMapper} from "../../customer/mappers/customer-detail.mapper";
import {PartnerDetailMapper} from "../../partner/mappers/partner-detail.mapper";
import {PartnerServiceModel} from "../../partner/services/models/partner-service.model";
import {CustomerServiceModel} from "../../customer/services/models/customer-service.model";
import {SystemSaveServiceModel, SystemServiceModel} from "../../system/services/models/system-service.model";
import {SystemSaveDetailMapper} from "../../system/mappers/system-save-detail.mapper";
import {TenantServiceModel} from "../../tenant/services/models/tenant-service.model";
import {TenantDetailModel} from "../../tenant/components/models/tenant-detail.model";
import {TenantDetailMapper} from "../../tenant/mappers/tenant-detail.mapper";
import {SystemDetailMapper} from "../../system/mappers/system-detail.mapper";
import {ProductServiceModel} from "../../product/services/models/product-service.model";
import {ProductDetailMapper} from "../../product/mappers/product-detail.mapper";
import {ProductDetailModel} from "../../product/components/models/product-detail.model";
import {ProductPriceDetailModel} from "../../productPrice/components/models/product-price-detail.model";
import {ProductPriceServiceModel} from "../../productPrice/services/models/product-price-service.model";
import {ProductPriceDetailMapper} from "../../productPrice/mappers/product-price-detail.mapper";

export class LipoFormMapper {
  public static toPartnerServiceModel(formGroup: FormGroup, logoUrl: string | null, addresses: Address[] = [], customers: CustomerDetailModel[] = []): PartnerServiceModel {
    let fieldValue = formGroup.getRawValue() as PartnerDetailModel;

    fieldValue.logoUrl = logoUrl;
    fieldValue.addresses = addresses;
    fieldValue.customers = customers;

    return PartnerDetailMapper.toPartnerServiceModel(fieldValue);
  }

  public static toCustomerServiceModel(formGroup: FormGroup, logoUrl: string | null, addresses: Address[] = [], systems: SystemDetailModel[] = []) : CustomerServiceModel {
    let fieldValue = formGroup.getRawValue() as CustomerDetailModel;

    fieldValue.logoUrl = logoUrl;
    fieldValue.addresses = addresses;
    fieldValue.systems = systems;

    return CustomerDetailMapper.toCustomerServiceModel(fieldValue);
  }

  public static toSystemSaveServiceModel(formGroup: FormGroup): SystemSaveServiceModel {
    let fieldValue = formGroup.getRawValue() as SystemSaveDetailModel;

    return SystemSaveDetailMapper.toSystemSaveServiceModel(fieldValue)
  }

  public static toSystemServiceModel(formGroup: FormGroup, tenantDetails: TenantDetailModel[] = []): SystemServiceModel {
    let fieldValue = formGroup.getRawValue() as SystemDetailModel;
    fieldValue.tenants = tenantDetails;

    return SystemDetailMapper.toSystemServiceModel(fieldValue)
  }
  public static toProductServiceModel(formGroup: FormGroup, productPrice: ProductPriceDetailModel[] = []): ProductServiceModel {
    let fieldValue = formGroup.getRawValue() as ProductDetailModel;
    fieldValue.productPrice = productPrice

    return ProductDetailMapper.toProductServiceModel(fieldValue);
  }

  public static toProductPriceSaveModel(formGroup: FormGroup): ProductPriceServiceModel {
    let fieldValue = formGroup.getRawValue() as ProductPriceDetailModel

    return ProductPriceDetailMapper.toProductPriceServiceModel(fieldValue)
  }

  public static toTenantSaveModel(formGroup: FormGroup, old?: TenantDetailModel): TenantServiceModel {
    let fieldValue = formGroup.getRawValue();
    let sap = fieldValue['sap'];
    let artifact = fieldValue['artifact'];
    let gitlab = fieldValue['gitlab'];
    let technical = fieldValue['technical'];
    let checkBoxes = fieldValue['checkBoxes'];
    let tenant = new TenantDetailModel(
      checkBoxes.active,
      null,
      artifact.artifactDate,
      checkBoxes.checkInbound,
      artifact.directory,
      gitlab.gitlabJobName,
      gitlab.gitlabProjectId,
      gitlab.gitlabRefName,
      technical.port,
      sap.sapCompanyDB,
      old?.sapPassword,
      sap.sapUser,
      technical.serviceName,
      checkBoxes.testSystem,
      checkBoxes.suspended,
      technical.duifLicense,
      technical.installNo,
      technical.endpointUrl,
      old?.id
    )

    return TenantDetailMapper.toTenantServiceModel(tenant);
  }
}
