import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from "@angular/core";
import {tap} from "rxjs";
import {SnackbarService} from "../services/snackbar.service";

export const errorResponseInterceptor: HttpInterceptorFn = (req, next) => {
  const _snackBar = inject(SnackbarService)
  return next(req).pipe(tap({
    next: () => {
    },
    error: (err) => _snackBar.Error(err.message),
  }));
};
