import {Component, OnInit} from '@angular/core';
import {LipoOverviewComponent} from "../../../shared/components/lipo-overview/lipo-overview.component";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {LipoOverviewModel} from "../../../shared/components/models/lipo-overview.model";
import {Router} from "@angular/router";
import {SystemDataService} from "../../services/system-data.service";
import {LipoObjectTypeEnum} from "../../../shared/enums/lipo-object-type.enum";
import {SystemServiceMapper} from "../../mappers/system-service.mapper";
import {SystemOverviewMapper} from "../../mappers/system-overview.mapper";
import {LipoButton} from "../../../shared/components/models/lipo-button";

@Component({
  selector: 'du-system-overview',
  standalone: true,
    imports: [
        LipoOverviewComponent
    ],
  templateUrl: './system-overview.component.html',
  styleUrl: './system-overview.component.scss'
})
export class SystemOverviewComponent implements OnInit{
  systemOverviewModel?: LipoOverviewModel;

  protected readonly LipoRouteEnum = LipoRouteEnum;

  constructor(
    private systemService: SystemDataService,
    protected router: Router) {
  }

  ngOnInit(): void {
    this.systemService.getSystems().subscribe({
      next: system => {
        let systemsOverview = system.map(SystemServiceMapper.toSystemOverviewModel);
        let systemCards = systemsOverview.map(SystemOverviewMapper.toLipoCard);

        this.systemOverviewModel = {
          title: LipoObjectTypeEnum.SYSTEM,
          buttons: LipoButton.build({
            text: "button.system.add",
            onClick: async () => await this.router.navigate([LipoRouteEnum.SYSTEM, 'new'])
          }),
          cards: systemCards
        }

      }
    });
  }
}
