import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {LipoObjectTypeEnum} from "../../../shared/enums/lipo-object-type.enum";
import moment from 'moment';

export class ProductPriceDetailModel implements LipoModelInterface {
  constructor(
    public price: number = 0,
    public currency: string = '',
    public validFrom: moment.Moment = moment(new Date()),
    public validTo: moment.Moment | null = null,
    public type: string = '',
    public id: number | null = null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }

  getType(): LipoObjectTypeEnum {
    return LipoObjectTypeEnum.PRODUCT_PRICE;
  }

}
