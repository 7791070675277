import {ProductPriceDetailModel} from "../components/models/product-price-detail.model";
import {ProductPriceServiceModel} from "../services/models/product-price-service.model";
import {LipoFormModel} from "../../shared/models/lipo-form.model";
import {LipoTab} from "../../shared/components/models/lipoTab";
import {LipoDetailModel} from "../../shared/models/lipo-detail.model";

export class ProductPriceDetailMapper {
  public static toProductPriceServiceModel(productPriceDetailModel: ProductPriceDetailModel): ProductPriceServiceModel {
    return {
      price: productPriceDetailModel.price,
      currency: productPriceDetailModel.currency,
      validFrom: productPriceDetailModel.validFrom.format('YYYY-MM-DD'),
      validTo: productPriceDetailModel.validTo!.format('YYYY-MM-DD'),
      type: productPriceDetailModel.type,
      id: productPriceDetailModel.id || 0
    }
  }

  public static toLipoDetailModel(price: ProductPriceDetailModel, formModel?: LipoFormModel, tabs?: LipoTab[]): LipoDetailModel {
    return new LipoDetailModel(
      price.id ? `${price.price} ${price.currency}` : 'price',
      ``,
      null,
      formModel,
      tabs,
      price.id
    )
  }
}
