import {Component, OnInit} from '@angular/core';
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {ActivatedRoute, Router} from "@angular/router";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {SystemDataService} from "../../services/system-data.service";
import {SystemServiceMapper} from "../../mappers/system-service.mapper";
import {forkJoin, Observable, of} from "rxjs";
import {
  LipoFormCheckbox,
  LipoFormControlModel,
  LipoFormDropdown,
  LipoFormNumeric,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {SystemDetailModel, SystemSaveDetailModel} from "../models/system-detail.model";
import {FormControl, Validators} from "@angular/forms";
import {LipoTab} from "../../../shared/components/models/lipoTab";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {LipoTableDisplayedColumns, LipoTableModel} from "../../../shared/components/models/lipo-table.model";
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {SystemDetailMapper} from "../../mappers/system-detail.mapper";
import {SystemSaveDetailMapper} from "../../mappers/system-save-detail.mapper";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";
import {v4 as uuidv4} from "uuid";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {TenantDetailModel} from "../../../tenant/components/models/tenant-detail.model";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {CustomerDataService} from "../../../customer/services/customer-data.service";
import {CustomerServiceModel} from "../../../customer/services/models/customer-service.model";
import {KeycloakRoleEnum} from "../../../shared/enums/keycloak-role.enum";
import {KeycloakService} from "keycloak-angular";
import {ConfigurationService} from "../../../shared/services/configuration.service";

@Component({
  selector: 'du-system-detail',
  standalone: true,
  imports: [
    LipoDetailComponent
  ],
  templateUrl: './system-detail.component.html',
  styleUrl: './system-detail.component.scss'
})
export class SystemDetailComponent implements OnInit {
  detailModel?: LipoDetailModel;
  id?: number;
  customers?: CustomerServiceModel[]

  private readonly _tenantTableUuid = uuidv4();
  downloadUrl: string;

  constructor(
    private configService: ConfigurationService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _systemService: SystemDataService,
    private _snackBar: SnackbarService,
    private _customerService: CustomerDataService,
    private readonly _keycloakService: KeycloakService,
  ) {
    this.downloadUrl = this.configService.configuration.downloadUrl;
  }

  ngOnInit(): void {
    this._customerService.getCustomers().subscribe({
      next: customer => this.customers = customer.content,
      complete: () => this.handleSystem(),
    });
  }

  handleSystem(){
    this._activatedRoute.data.subscribe({
      next: data => {
        let routesDataModel = data as LipoRoutesDataModel;
        if (routesDataModel.isCreateItem) {
          this.createEmptySystem();
        } else {
          this._activatedRoute.paramMap.subscribe({
            next: paramMap => {
              let idParam = paramMap.get('systemId');
              if (!idParam) return;

              let id = +idParam;
              this.id = id
              this.loadSystem(+id);
            }
          });
        }
      }
    });
  }

  loadSystem(id: number): void {
    this._systemService.getSystem(id).subscribe({
      next: value => {
        let systemDetailModel = SystemServiceMapper.toSystemDetailModel(value)
        this.createDetail(systemDetailModel);
      }
    });
  }

  createEmptySystem(): void {
    let emptySystemModel = new SystemSaveDetailModel();
    this.createSaveDetail(emptySystemModel);
  }

  createSaveDetail(systemDetailModel: SystemSaveDetailModel): void {
    let adminForm$ = this._keycloakService.isUserInRole(KeycloakRoleEnum.ADMIN) ? this.getAdminSaveFormFields(systemDetailModel) : of([]);

    forkJoin({
      adminForm: adminForm$,
      baseForm: this.getSystemSaveDetailFormFields(systemDetailModel),
    }).subscribe(({adminForm, baseForm}) => {
      let baseForms = [...baseForm, ...adminForm] as LipoFormControlModel[];
      let lipoFormModel = new LipoFormModel(baseForms);
      this.detailModel = SystemSaveDetailMapper.toLipoDetailSaveModel(systemDetailModel, lipoFormModel);
    });
  }

  createDetail(systemDetailModel: SystemDetailModel): void {
    this.getSystemDetailFormFields(systemDetailModel).subscribe({
      next: baseForms => {
        let tabs = this.getTabs(systemDetailModel);
        let lipoFormModel = new LipoFormModel(baseForms);
        this.detailModel = SystemDetailMapper.toLipoDetailModel(systemDetailModel, lipoFormModel, tabs);
      }
    });
  }

  getSystemDetailFormFields(model: SystemDetailModel): Observable<LipoFormControlModel[]> {
    const fields: LipoFormControlModel[] = [
      new LipoFormTextbox({
        value: new FormControl(model.name, Validators.required),
        key: 'name',
        label: 'name',
      }),
      new LipoFormDropdown({
        value: new FormControl(this.customers?.find(customer => customer.id === model.customer)?.id, Validators.required),
        key: 'customer',
        label: 'customer',
        options: this.customers?.map(customer => ({key: customer.name, value: customer.id})),
      }),
      new LipoFormTextbox({
        value: new FormControl(model.sapSystemId, Validators.required),
        key: 'sapSystemId',
        label: 'sapSystemId',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.identity, Validators.required),
        key: 'identity',
        label: 'identity',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.hardwareKey),
        key: 'hardwareKey',
        label: 'hardware_key',
      }),
      new LipoFormTextbox({
        value: new FormControl({value: model.initialDate, disabled: true}),
        key: 'initialDate',
        label: 'initial_date',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.logoUrl),
        key: 'logoUrl',
        label: 'logo_url',
      }),
      new LipoFormCheckbox({
        value: new FormControl(model.isActive),
        key: 'isActive',
        label: 'active',
      }),
    ];
    return of(fields);
  }

  getSystemSaveDetailFormFields(model: SystemSaveDetailModel): Observable<LipoFormControlModel[]> {
    const fields: LipoFormControlModel[] = [
      new LipoFormTextbox({
        value: new FormControl(model.name, Validators.required),
        key: 'name',
        label: 'name',
      }),
      new LipoFormDropdown({
        value: new FormControl(this.customers?.find(customer => customer.id === model.customer)?.id, Validators.required),
        key: 'customer',
        label: 'customer',
        options: this.customers?.map(customer => ({key: customer.name, value: customer.id})),
      }),
      new LipoFormTextbox({
        value: new FormControl(model.identity, Validators.required),
        key: 'identity',
        label: 'identity',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.hardwareKey),
        key: 'hardwareKey',
        label: 'hardware_key',
      }),
    ];
    return of(fields);
  }

  getAdminSaveFormFields(model: SystemSaveDetailModel): Observable<LipoFormControlModel[]> {
    const adminFields: LipoFormControlModel[] = [
      new LipoFormNumeric({
        value: new FormControl(model.gitlabProjectId),
        key: 'gitlabProjectId',
        label: 'gitlab_project_id',
      }),
    ];
    return of(adminFields);
  }

  getTabs(model: SystemDetailModel): LipoTab[] {
    return LipoTab.build(
      {
        title: 'tenants',
        components: [
          {
            uuid: this._tenantTableUuid,
            component: this.getTenantsTable(model.tenants)
          }
        ]
      }
    );
  }

  getTenantsTable(tenants: TenantDetailModel[]) {
    let tableDataSource = new MatTableDataSource<TenantDetailModel>(tenants)
    let selectionModel = new SelectionModel<TenantDetailModel>(true, [])
    let displayedColumns = LipoTableDisplayedColumns.build(
      {HeaderCellName: 'status', PropertyName: 'isActiveText'},
      {HeaderCellName: 'sapCompanyDB', PropertyName: 'sapCompanyDB'},
      {HeaderCellName: 'serviceName', PropertyName: 'serviceName'},
      {HeaderCellName: 'testSystem', PropertyName: 'testSystem'},
      {HeaderCellName: 'port', PropertyName: 'port'},
      {HeaderCellName: 'check_inbound', PropertyName: 'checkInbound'},
      {HeaderCellName: 'directory', PropertyName: 'directory'},
      {HeaderCellName: 'suspended', PropertyName: 'suspended'},
      {HeaderCellName: 'duifLicense', PropertyName: 'duifLicense'},
      {HeaderCellName: 'installNo', PropertyName: 'installNo'},
      {HeaderCellName: 'endpointUrl', PropertyName: 'endpointUrl'},
    );
    let buttons = LipoButton.build({
      text: "button.tenant.add",
      onClick: () => this._router.navigate([LipoRouteEnum.SYSTEM, this.id, LipoRouteEnum.TENANT, 'new']),
    });

    return new LipoTableModel<TenantDetailModel>(
      tableDataSource,
      selectionModel,
      displayedColumns,
      buttons,
      (value) => this.onRowClick(value)
    );
  }

  async onRowClick(value: LipoModelInterface) {
    let id = value.getId();

    let tenantList = this.detailModel?.findComponent<LipoTableModel<TenantDetailModel>>(this._tenantTableUuid)
    let tenant = tenantList?.tableDataSource.data.find(data => data.getId() === id);

    if (tenant) {
      await this._router.navigate([
        LipoRouteEnum.SYSTEM,
        this.id,
        LipoRouteEnum.TENANT,
        tenant.id
      ]);
    }
  }

  onSaveClick(detailModel: LipoDetailModel, id: number | undefined): void {
    if (!detailModel.form?.formGroup) return;

    if (id && id > 0) {
      let tenantTab = detailModel.findComponent<LipoTableModel<TenantDetailModel>>(this._tenantTableUuid);
      let systemServiceModel = LipoFormMapper.toSystemServiceModel(detailModel.form?.formGroup, tenantTab?.tableDataSource.data);
      systemServiceModel.id = id;

      this._systemService.updateSystem(systemServiceModel).subscribe({
        next: system => {
          this.createDetail(SystemServiceMapper.toSystemDetailModel(system));
          this._snackBar.Saved();
        }
      });
    } else {
      let systemSaveServiceModel = LipoFormMapper.toSystemSaveServiceModel(detailModel.form?.formGroup);

      this._systemService.createSystem(systemSaveServiceModel).subscribe({
        next: system => {
          this._router.navigate([LipoRouteEnum.SYSTEM, system.id]).then(() => this._snackBar.Saved());
        }
      })
    }
  }

  onDeleteClicked(id: number | undefined): void {
    if (!id) return;

    this._systemService.deleteSystem(id).subscribe({
      next: (successfully) => {
        if (successfully) {
          this._router.navigate([LipoRouteEnum.SYSTEM]).then(() => this._snackBar.Deleted());
        }
      }
    });
  }

  onDownloadClick(): void {
    const link = document.createElement('a');
    link.href = this.downloadUrl;
    link.download = 'DUAgentSetup.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  protected readonly LipoRouteEnum = LipoRouteEnum;
}
