<div class="layout-column sidenav-container">
  <div class="layout-row-wrap layout-align-space-between-center">
    <div>
      <img ngSrc="../../assets/images/logo.png" alt="logo" class="logo" height="200" width="200" priority/>
    </div>
    <div class="language-selector">
      <mat-select class="language-selector" panelClass="language-selector-panel" [value]="currentLanguage.value"
                  (valueChange)="setNewLanguage($event)"
                  hideSingleSelectionIndicator>
        <mat-select-trigger>
          <div class="layout-row layout-align-start-center" style="padding: 0 12px">
            <span>{{ currentLanguage.label }}</span>
          </div>
        </mat-select-trigger>
        <mat-option *ngFor="let opt of languages" [value]="opt.value">
          <div class="layout-row layout-align-start-center" style="color: black;">
            <span>{{ opt.label }}</span>
          </div>
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div class="sidenav-body">
    <h2>{{ 'license_portal' | translate }}</h2>

    <div class="button-container">
      <button *ngIf="isAdmin" mat-button [routerLink]="LipoRouteEnum.PARTNER" routerLinkActive="active-link">
        <span class="material-symbols-outlined">partner_exchange</span>
        <span>{{ 'partners' | translate }}</span>
      </button>
      <button *ngIf="isPartner || isAdmin" mat-button [routerLink]="LipoRouteEnum.CUSTOMER"
              routerLinkActive="active-link">
        <span class="material-symbols-outlined">groups</span>
        <span>{{ 'customers' | translate }}</span>
      </button>
      <button mat-button [routerLink]="LipoRouteEnum.SYSTEM" routerLinkActive="active-link">
        <span class="material-symbols-outlined">dns</span>
        <span>{{ 'systems' | translate }}</span>
      </button>
      <button mat-button [routerLink]="LipoRouteEnum.LICENCE" routerLinkActive="active-link">
        <span class="material-symbols-outlined">license</span>
        <span>{{ 'licenses' | translate }}</span>
      </button>
      <button mat-button [routerLink]="LipoRouteEnum.PRODUCT" routerLinkActive="active-link">
        <span class="material-symbols-outlined">apps</span>
        <span>{{ 'products' | translate }}</span>
      </button>
    </div>
  </div>

  <div class="sidenav-footer">
    <div class="logout-container layout-column" *ngIf="_keycloak.isLoggedIn(), else loggedOut">
      <div class="layout-row layout-align-space-between-center">
        <div class="account-imag-container">
          <img [ngSrc]="userProfilePicture" class="account-image"
               alt="account" height="55" width="55">
        </div>
        <div>
          <button mat-icon-button>
            <mat-icon fontIcon="settings"/>
          </button>
          <button mat-icon-button (click)="logout()">
            <mat-icon fontIcon="logout"/>
          </button>
        </div>
      </div>
      <div class="layout-row">
        @if (userProfile) {
          <div class="layout-column layout-gap-0">
            <div class="profile-title">
              @if (userProfile.firstName && userProfile.lastName) {
                <span>{{ userProfile.firstName }} {{ userProfile.lastName }}</span>
              } @else if (userProfile.username) {
                <span>{{ userProfile.username }}</span>
              }
            </div>
            <div class="profile-subtitle">
              @if (userProfile.email) {
                <span>{{ userProfile.email }}</span>
              }
            </div>
          </div>
        }
      </div>
    </div>
    <ng-template #loggedOut>
      <button mat-raised-button (click)="login()">{{ 'login' | translate }}</button>
    </ng-template>
  </div>
</div>
