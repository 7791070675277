import {Component, OnInit} from '@angular/core';
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {ProductPriceDetailModel} from "../models/product-price-detail.model";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {ProductDataService} from "../../../product/services/product-data.service";
import {ProductPriceDataService} from "../../services/product-price-data.service";
import {ProductPriceServiceMapper} from "../../mappers/product-price-service.mapper";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {TranslateService} from "@ngx-translate/core";
import {Observable, of} from "rxjs";
import {ProductPriceDetailMapper} from "../../mappers/product-price-detail.mapper";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";
import {
  LipoFormControlModel,
  LipoFormDate, LipoFormDropdown,
  LipoFormNumeric,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {ConfigurationService} from "../../../shared/services/configuration.service";

@Component({
  selector: 'du-product-price-detail',
  standalone: true,
    imports: [
        LipoDetailComponent
    ],
  templateUrl: './product-price-detail.component.html',
  styleUrl: './product-price-detail.component.scss'
})
export class ProductPriceDetailComponent implements OnInit {
  detailModel?: LipoDetailModel;
  productId: number | null = null;
  priceId: number | null = null;

  protected readonly LipoRouteEnum = LipoRouteEnum;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _snackBar: SnackbarService,
    private _productService: ProductDataService,
    private _priceService: ProductPriceDataService,
    private _translationService: TranslateService,
    private _configService: ConfigurationService,
    ) {
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        this.handleRouteData(data);
      }
    });
  }

  setPrice(price: ProductPriceDetailModel): void {
    this.createDetail(price);
  }

  createEmptyPrice(): void {
    let emptyPriceModel = new ProductPriceDetailModel();
    this.createDetail(emptyPriceModel);
  }

  createDetail(priceModel: ProductPriceDetailModel): void {
    this.getFormFields(priceModel).subscribe({
      next: baseForms => {
        let lipoFormModel = new LipoFormModel(baseForms)
        this.detailModel = ProductPriceDetailMapper.toLipoDetailModel(priceModel, lipoFormModel)
      }
    });
  }

  getFormFields(model: ProductPriceDetailModel): Observable<LipoFormControlModel[]> {
    const fields: LipoFormControlModel[] = [
      new LipoFormNumeric({
        value: new FormControl(model.price, [Validators.required, Validators.min(0)]),
        key: 'price',
        label: 'price',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.currency, [Validators.required, Validators.maxLength(3)]),
        key: 'currency',
        label: 'currency'
      }),
      new LipoFormDate({
        value: new FormControl(model.validFrom, Validators.required),
        key: 'validFrom',
        label: 'valid.from',
      }),
      new LipoFormDate({
        value: new FormControl(model.validTo, Validators.required),
        key: 'validTo',
        label: 'valid.to'
      }),
      new LipoFormDropdown({
        value: new FormControl(model.type, Validators.required),
        key: 'type',
        label: 'subscription.type',
        options: this._configService.configuration.subscriptionTypes.map(it =>  ({key: it.translation, value: it.name})),
        order: 99,
      })
    ]

    return of(fields)
  }

  onDeleteClicked(): void {
    if (!this.productId || !this.priceId) return;

    this._priceService.deleteProductPrice(this.productId, this.priceId).subscribe({
      next: (successfully) => {
        if (successfully) {
          this._router.navigate([LipoRouteEnum.PRODUCT, this.productId]).then(() => this._snackBar.Deleted());
        }
      }
    })
  }

  onSaveClick(detailModel: LipoDetailModel): void {
    if (!detailModel.form?.formGroup || !this.productId) return;

    let servicePriceModel = LipoFormMapper.toProductPriceSaveModel(detailModel.form?.formGroup)
    let id = this.priceId

    if (id && id > 0) {
      servicePriceModel.id = id;
      this._priceService.updateProductPrice(this.productId, servicePriceModel).subscribe({
        next: price => {
          this.createDetail(ProductPriceServiceMapper.toProductPriceDetailModel(price));
          this._snackBar.Saved();
        }
      });
    } else {
      this._priceService.createProductPrice(servicePriceModel, this.productId).subscribe({
        next: product => {
          this._router.navigate([LipoRouteEnum.PRODUCT, product.id]).then(() => this._snackBar.Saved());
        }
      })
    }
  }

  private handleRouteData(data: Data): void {
    const routesDataModel = data as LipoRoutesDataModel;
    this.handleParams();
    this.handlePriceData(routesDataModel);
  }

  private handleParams(): void {
    this._activatedRoute.paramMap.subscribe(params => {
      this.productId = this.idToNumber(params.get('productId'));
      this.priceId = this.idToNumber(params.get('productPriceId'));
    });
  }

  private idToNumber(idString: string | null): number | null {
    if (idString === null) {
      return null;
    }
    const num = +idString;
    return isNaN(num) ? null : num;
  }

  private handlePriceData(routesDataModel: LipoRoutesDataModel): void {
    if (routesDataModel.isCreateItem) {
      this.createEmptyPrice();
    } else {
      if (this.productId !== null) {
        this._productService.getProduct(this.productId).subscribe({
          next: value => {
            let priceServiceModel = value.productPrice.find(price => price.id === this.priceId)
            if (priceServiceModel !== undefined) {
              let price = ProductPriceServiceMapper.toProductPriceDetailModel(priceServiceModel);
              this.setPrice(price);
            } else {
              this._router.navigate([LipoRouteEnum.PRODUCT, this.productId]).then(() => this._snackBar.Warning(this._translationService.instant('snackbar.notfound')))
            }
          }
        });
      }
    }
  }
}
