export enum LipoFormControlTypeEnum {
  TEXT = 0,
  NUMERIC = 1,
  TEXTAREA = 2,
  PHONE = 3,
  MAIL = 4,
  DROPDOWN = 5,
  CHECKBOX = 6,
  RADIO = 7,
  PASSWORD = 8,
  DATE = 9,
}
