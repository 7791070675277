<div class="layout-column detail-table-container">
  <div class="layout-row layout-align-space-between-center detail-table-header responsive-layout">
    <div class="subtitle-container">
      <h2 class="mat-body-2 detail-table-subtitle">
        {{ lipoTable.tableDataSource.data.length }} {{ lipoTable.tableDataSource.data.length | i18nPlural: ('entry_map' | translate) }}
      </h2>
    </div>
    <div class="detail-table-header-flex-align-center search-container">
      @for (button of lipoTable.buttons; track $index) {
        <button mat-stroked-button class="detail-table-header-add-button {{button.className}}"
                (click)="button.onClick()">{{ button.text | translate | uppercase }}
        </button>
      }
      <div class="dense-4">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" color="accent">
          <mat-label>{{ 'search' | translate }}</mat-label>
          <input matInput (keyup.enter)="applyFilter($event)">
          <mat-icon matSuffix fontIcon="search"></mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div>
    <table mat-table [dataSource]="lipoTable.tableDataSource" matSort>

      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="detail-table-header-cell">
          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                        [checked]="lipoTable.selectionModel.hasValue() && isAllSelected()"
                        [indeterminate]="lipoTable.selectionModel.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="detail-table-body-cell">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? lipoTable.selectionModel.toggle(row) : null"
                        [checked]="lipoTable.selectionModel.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Position Column -->
      @for (column of lipoTable.displayedColumns; track column) {
        <ng-container [matColumnDef]="column.PropertyName">
          <th mat-header-cell *matHeaderCellDef> {{ pipeValue(column.Pipes, column.HeaderCellName) }}</th>
          <td mat-cell *matCellDef="let element"> {{ pipeValue(column.Pipes, element[column.PropertyName]) }}</td>
        </ng-container>
      }

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"
          (click)="handleClickEvent(row)">
      </tr>

    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
