import {CustomerOverviewModel} from "../components/models/customer-overview.model";
import {CustomerServiceModel} from "../services/models/customer-service.model";
import {CustomerDetailModel} from "../components/models/customer-detail.model";
import {SystemServiceMapper} from "../../system/mappers/system-service.mapper";


export class CustomerServiceMapper {
  public static toCustomerOverviewModel(customerServiceModel: CustomerServiceModel): CustomerOverviewModel {
    return new CustomerOverviewModel(
      customerServiceModel.id,
      customerServiceModel.name,
      customerServiceModel.phone,
      customerServiceModel.logo,
      customerServiceModel.systems
    )
  }

  public static toCustomerDetailModel(customerServiceModel: CustomerServiceModel): CustomerDetailModel {
    return new CustomerDetailModel(
      customerServiceModel.id,
      customerServiceModel.name,
      customerServiceModel.mail,
      customerServiceModel.phone,
      customerServiceModel.partner,
      customerServiceModel.addresses,
      customerServiceModel.systems.map(SystemServiceMapper.toSystemDetailModel),
      customerServiceModel.logo
    )
  }
}
