import {FormGroup} from "@angular/forms";
import {LipoFormControlModel} from "../components/models/lipo-form-control.model";
import {LipoFormGroupModel} from "../components/models/lipo-form-group.model";

export class LipoFormModel {
  formGroup: FormGroup;
  style: { gap: number };

  constructor(
    public baseForms: (LipoFormControlModel | LipoFormGroupModel)[],
    style?: { gap: number },
  ) {
    this.formGroup = this.toFormGroup(baseForms);
    this.style = style ?? {gap: 24};
  }

  private toFormGroup(baseForms: (LipoFormControlModel | LipoFormGroupModel)[]): FormGroup {
    const group: any = {};
    baseForms.sort((a, b) => a.order - b.order);
    baseForms.forEach(baseForm => {
      if (baseForm instanceof LipoFormGroupModel) {
        group[baseForm.key] = this.toFormGroup(baseForm.controls);
      } else {
        group[baseForm.key] = baseForm.value;
      }
    });

    return new FormGroup(group);
  }
}
